<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员档案统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:3rem">姓名:</span>
              <el-input v-model="Name" type="text" size="small" placeholder="请输入姓名" clearable />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="idCard" type="text" size="small" placeholder="请输入身份证号" clearable />
            </div>
             <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel">单位名称:</span>
              <el-select
                size="small"
                v-model="unitCompId"
                remote
                :remote-method="getuserCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width:100%"
              >
                <el-option
                  v-for="item in userCompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <!-- <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:3rem">状态:</span>
              <el-select v-model="learningstate" clearable placeholder="请选择" size="small">
                <el-option
                  v-for="item in archivesList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div> -->
            <div class="df" style="padding-right:20px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" width="100" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" />
                <el-table-column label="单位名称" align="left" show-overflow-tooltip prop="compName" />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                minWidth="150"
              />
            
              <el-table-column label="档案编号" align="left" show-overflow-tooltip prop="certNo" />
              <el-table-column label="操作" align="center" width="100px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 15px"
                    @click="lookupTrainDocument(scope.row.projectId, scope.row.userId)"
                    :disabled="scope.row.certState=='0'"
                  >查看学员档案</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
      title="查看学员档案"
      :visible.sync="dialogDownload"
      width="50%"
      center
      :before-close="closeDownloadTrainDocument"
    >
      <div id="pdf-content" style="height: 800px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "set/StudentFileStatistics",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      Name: "",
      idCard: "",
      projectName: "",
      CourseNo: "",
      LearningstateList: [], // 单位类型
      learningstate: "",
      archivesList: [
        {
          value: "1",
          label: "已生成"
        },
        {
          value: "0",
          label: "未生成"
        },
        {
          value: "",
          label: "全部"
        }
      ],
      dialogDownload: false,
      unitCompId:'',
      userCompanyList:[],
    };
  },
  created() {
    //每天首次进入的时候弹提示--------- 开始
    let userId = JSON.parse(sessionStorage.getItem("userJson")).userId;
    //获取当前时间
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    let nowDate = year + "-" + month + "-" + day;
    console.log(localStorage.getItem(userId));
    if (!localStorage.getItem(userId)) {
      this.$confirm(
        "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "提示",
        {
          showClose: false, //是否显示右上角关闭按钮
          showCancelButton: false, //是否显示取消按钮
          closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
          confirmButtonText: "同意并继续",
          confirmButtonClass: "bgc-bv"
        }
      )
        .then(() => {
          let userKey = {};
          userKey["StudentFileQuery"] = nowDate;
          localStorage.setItem(userId, JSON.stringify(userKey));
        })
        .catch(() => {});
    } else if (
      JSON.parse(localStorage.getItem(userId)).StudentFileQuery == undefined ||
      JSON.parse(localStorage.getItem(userId)).StudentFileQuery < nowDate
    ) {
      this.$confirm(
        "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "提示",
        {
          showClose: false, //是否显示右上角关闭按钮
          showCancelButton: false, //是否显示取消按钮
          closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
          confirmButtonText: "同意并继续",
          confirmButtonClass: "bgc-bv"
        }
      )
        .then(() => {
          let userKey = JSON.parse(localStorage.getItem(userId));
          userKey.StudentFileQuery = nowDate;
          localStorage.setItem(userId, JSON.stringify(userKey));
        })
        .catch(() => {});
    }
    //每天首次进入的时候弹提示-------- 结束
  },
  methods: {
          /* 所属企业 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("sys/company/queryCompanyFJList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.userCompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.userCompanyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.Name) {
        params.userName = this.Name;
      }
      if (this.idCard) {
        params.idcard = this.idCard;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.learningstate) {
        params.state = this.learningstate;
      }
       if (this.unitCompId) {
        params.compNameId = this.unitCompId;
      }
      this.doFetch({
        url: "/gov/project/queryProjectCertJGList",
        params,
        pageNum
      });
    },
    /* 查看学习记录 */
    lookupTrainDocument(projectId, userId) {
      let parmar = {
        projectId: projectId,
        userId: userId
      };
      this.$post("/cert/downloadTrainDocument", parmar).then(res => {
        if (res.status == 0) {
          this.dialogDownload = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-content");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    closeDownloadTrainDocument() {
      this.dialogDownload = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less">
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
